import type { FC } from 'react';
import React from 'react';

import { EndOfPageView } from './Views';
import type { PageCardContextProviderProps } from './PageCardContextProvider';
import { PageCardContextProvider } from './PageCardContextProvider';

type EndOfPageCardProps = Omit<PageCardContextProviderProps, 'appearance'>;

export const EndOfPageCard: FC<EndOfPageCardProps> = (wrapperProps) => (
	<PageCardContextProvider {...wrapperProps} appearance="end-of-page">
		<EndOfPageView />
	</PageCardContextProvider>
);
