import type { FC } from 'react';
import React, { Fragment, useMemo, useEffect, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { EOP_RECS_EXPERIENCE, ExperienceTrackerContext } from '@confluence/experience-tracker';
import { PageSegmentLoadStart } from '@confluence/browser-metrics';
import {
	ErrorDisplay,
	ErrorBoundary,
	isUnauthorizedError,
	Attribution,
} from '@confluence/error-boundary';
import { fg } from '@confluence/feature-gating';
import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';
import { useContentState } from '@confluence/content-state/entry-points/useContentState';
import type { GlobalPageCard } from '@confluence/page-card';
import { getQueryParamsAndSetFetchPolicyForNetworkOnlyQuery } from '@confluence/query-preloader-tools';

import { END_OF_PAGE_RECOMMENDATIONS_METRIC } from './perf.config';
import { EndOfPageRecommendationQuery } from './EndOfPageRecommendationQuery.graphql';
import { transformEndOfPageRecContent } from './transformers';
import type {
	EndOfPageRecommendationQuery as EndOfPageRecommendationData,
	EndOfPageRecommendationQueryVariables,
} from './__types__/EndOfPageRecommendationQuery';
import { EndOfPageRecPlaceholderContainer } from './styled';
import { useWhichEndOfPageRecContainerToRender } from './useWhichEndOfPageRecContainerToRender';
import { EndOfPageRecommendationComponent } from './EndOfPageRecommendationComponent';

type EndOfPageRecommendationProps = {
	entityId: string;
	entityType: string;
	pageWidthType: string;
	spaceKey: string;
};

export const EndOfPageRecommendation: FC<EndOfPageRecommendationProps> = ({
	entityType,
	entityId,
	pageWidthType,
	spaceKey,
}) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const [state] = useContentState();
	const skip = entityType !== 'page' && entityType !== 'blogpost';
	const experienceTracker = useContext(ExperienceTrackerContext);

	const { error, loading, data } = useQuery<
		EndOfPageRecommendationData,
		EndOfPageRecommendationQueryVariables
	>(
		...getQueryParamsAndSetFetchPolicyForNetworkOnlyQuery({
			query: EndOfPageRecommendationQuery,
			variables: {
				type: 'page',
				id: entityId,
				exp: 'end_of_page',
				key: spaceKey,
			},
			context: { single: true },
			errorPolicy: 'all',
			skip,
		}),
	);

	/**
	 * The ordering of recs array is determined by the "ranking" in the backend.
	 * Each rec will have a corresponding "strategies" array that can have one or multiple strategies.
	 * We want to ensure that we are choosing strategies from each rec in a way that avoids monotony on the UI.
	 * To accomplish this, we must first sort the recs array depending on the number of strategies each rec has, in
	 * ascending order. We then pass each rec to transformEndOfPageRecContent which helps to choose the strategy, and
	 * finally we return the transformed array back to the intial ranking order determind by the backend
	 */
	const transformAndOrderRecs = (recs) => {
		if (!recs) return;

		const strategiesCounter = new Map();
		const recsSortedByStrategyCount = [...recs].sort((a, b) =>
			a.strategy.length > b.strategy.length ? 1 : -1,
		);
		const transformedRecs = recsSortedByStrategyCount.map((rec) =>
			transformEndOfPageRecContent(rec, strategiesCounter),
		);

		// return to initial order
		const pageCards: GlobalPageCard[] = new Array();
		recs.forEach((rec) => {
			const el = transformedRecs.find((card) => card.id === rec.content.id) as GlobalPageCard;
			pageCards.push(el);
		});
		return pageCards;
	};

	const pageCardData = useMemo(
		() => transformAndOrderRecs(data?.getRecommendedPages?.recommendedPages),
		[data],
	);

	const isEnabled = data?.getRecommendedPages.status?.isEnabled;
	const whichEndOfPageRecContainerToRenderHook = useWhichEndOfPageRecContainerToRender();

	useEffect(() => {
		if (isEnabled) {
			experienceTracker.start({
				name: EOP_RECS_EXPERIENCE,
			});
		}
	}, [experienceTracker, isEnabled]);

	useEffect(() => {
		if (isEnabled && !loading) {
			if (error) {
				experienceTracker.stopOnError({
					name: EOP_RECS_EXPERIENCE,
					error,
				});
			} else {
				experienceTracker.succeed({
					name: EOP_RECS_EXPERIENCE,
				});
			}
		}
	}, [experienceTracker, isEnabled, loading, error]);

	if (
		skip ||
		isUnauthorizedError(error) ||
		!pageCardData ||
		pageCardData.length < 2 ||
		!isEnabled
	) {
		return null;
	}

	if (error) {
		return (
			<ErrorBoundary attribution={Attribution.SMARTS}>
				<ErrorDisplay error={error} />
			</ErrorBoundary>
		);
	}

	let isContentReady = state.isContentReady;

	if (fg('ssr_end_of_page_recommendation')) {
		isContentReady = !!process.env.REACT_SSR || window.__SSR_RENDERED__ || state.isContentReady;
	}

	return (
		<div
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className="end-of-page-rec-wrapper"
			data-testid="end-page-rec-wrapper"
			data-vc="end-of-page-recommendation"
			{...ssrPlaceholderIdProp}
		>
			{isEnabled &&
				isContentReady &&
				(loading ? (
					<EndOfPageRecPlaceholderContainer />
				) : (
					<Fragment>
						<PageSegmentLoadStart
							key={entityId}
							metric={END_OF_PAGE_RECOMMENDATIONS_METRIC}
							isCustomStart
						/>
						<EndOfPageRecommendationComponent
							entityId={entityId}
							entityType={entityType}
							pageCardData={pageCardData}
							data={data}
							pageWidthType={pageWidthType}
							spaceKey={spaceKey}
							containerCohort={whichEndOfPageRecContainerToRenderHook}
						/>
					</Fragment>
				))}
		</div>
	);
};
