import React from 'react';

import { Box, Grid, xcss } from '@atlaskit/primitives';

import { EndOfPageCard } from '@confluence/page-card/entry-points/EndOfPageCard';
import { expValEquals } from '@confluence/feature-experiments';
import type { GlobalPageCard } from '@confluence/page-card';

export type EndOfPageRecGridProps = {
	pageCardData: GlobalPageCard[];
	pageRecStrategy?: string[][];
	entityId: string;
};

const TwoCardWrapperWidth = xcss({
	maxWidth: '480px',
});

const ThreeCardWrapperWidth = xcss({
	maxWidth: '320px',
});

const SmallCardHeight = xcss({
	height: '88px',
});

const BigCardHeight = xcss({
	height: '128px',
});

const getColumnsBasedOnNumberOfCards = (totalCards: number) => {
	const twoColumns = '1fr 1fr';
	const threeColumns = 'repeat(auto-fit, minmax(320px, 1fr))';
	switch (totalCards) {
		case 1:
		case 2:
		case 4:
			return twoColumns;
		default:
			return threeColumns;
	}
};

const getCardStyles = (totalCards: number, shouldMakeCardSmaller: boolean) => {
	let stylesArray;
	switch (totalCards) {
		case 1:
		case 2:
		case 4:
			stylesArray = [TwoCardWrapperWidth];
			break;
		default:
			stylesArray = [ThreeCardWrapperWidth];
			break;
	}
	shouldMakeCardSmaller ? stylesArray.push(SmallCardHeight) : stylesArray.push(BigCardHeight);
	return stylesArray;
};

export const EndOfPageRecGrid = ({
	pageCardData,
	pageRecStrategy,
	entityId,
}: EndOfPageRecGridProps) => {
	return (
		<Grid templateColumns={getColumnsBasedOnNumberOfCards(pageCardData.length)} gap="space.200">
			{pageCardData.map((node, i) => (
				<Box
					key={node.id}
					xcss={() =>
						getCardStyles(
							pageCardData.length,
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
							expValEquals('cc_smarts_related_pages_card_smaller_experiment', 'cohort', 'test'),
						)
					}
				>
					<EndOfPageCard
						data={node}
						ranking={i}
						smallCard={expValEquals(
							'cc_smarts_related_pages_card_smaller_experiment',
							'cohort',
							'test',
						)}
						analyticsData={{
							source: 'endOfPageRecommendations',
							attributes: {
								strategy: pageRecStrategy ? pageRecStrategy[i % pageRecStrategy.length] : null,
								mainContentId: entityId,
							},
						}}
					/>
				</Box>
			))}
		</Grid>
	);
};
